// JavaScript Document
/*///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////MESSAGES VARIABLE/////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////*/
var messages = {
    requireText : "You must complete all the mandatory fields (*).",
    textText1: "You can't enter more than ",
    textText2: " characters in this field.",
    textUnderText1: "Your password must have at least ",
    textUnderText2: " characters.",
    numberText: "This field only allow numbers.",
    emailText: "Please enter a valid email.",
    hiddenText: "There was an error. Please try again later.",
    passwordNumberText: "Your password must have at least one number (0-9).",
    passwordLowerText: "Your password must have at least one lowercase letter (a-z)!",
    passwordUpperText: "Your password must have at least one uppercase letter (A-Z)!",
    passwordSpecialText: "Your password must have one of the following special characters (!@%^*-.)!",
    passwordConfirmText: "Your passwords don't match. Please write the same password."
};

/*///////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////AVOIDING JACK FUNCTION//////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////*/
//Function to avoid highjacking and malicious code injection
function checkJack(idForm){  
	$("#" + idForm).find('input, textarea, select.choose').each(function() { 
        var element = this;
		var inputName = element.id; 
		var elementValue = element.value.trim(); 

		if (($(this).hasClass("text")) || ($(this).hasClass("num")) || ($(this).hasClass("email")) || ($(this).hasClass("hidden")) || ($(this).hasClass("message")) || ($(this).hasClass("password")) || ($(this).hasClass("confirm_password"))){
			if(elementValue){
				var str = document.getElementById(inputName).innerHTML; 
	            var res = str.replace(/script/i, "").replace(/html/i, "").replace(/php/i, "").replace(/div/i, "").replace(/href/i, "").replace(/function/i, "");
	            document.getElementById(inputName).innerHTML = res;
			}
		}
	});
}

/*///////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////INPUT VALIDATION/////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////*/
//Validation function to evaluate any form in AJAX functions - Return true or false
function validateForAjax(idForm){ 
	//Regular expressions patterns & variables neccesaries to evaluate each input
	var validationResult = {
        "result" : true,
        "msg" : ""
    };
	var flag=true;
	var b=/^[^@\s]+@[^@\.\s]+(\.[^@\.\s]+)+$/;
	var re = /[0-9]/;
	var upl = /[A-Z]/;
	var lwl = /[a-z]/; 
	var spc = /[!@#$%^&*-._]/;
    //Declaring permitted length according to input type
    var textLength = 150;
    var mediumTextLength = 350;
    var largeTextLength = 5000;
    var linkLength = 500;
    var numLength = 20;
    var emailLength = 150;
    var hiddenLength = 100;
    var passwordMaxlength = 20;
    var passwordMinlength = 8;
    
	/*We loop every input existing on the form to look for some classes and check for valid 
    content -------------------------------------------------------------------------------
    ---------------------------------------------------------------------------------------
    ---------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------*/
	$("#" + idForm).find('input, textarea, select.choose').not('.select-dropdown').each(function() {

        //Identifying the current input to evaluate and storing it's name and value
        var element= this;
		var inputName = element.id;
		var elementValue = element.value.trim();
		//Checkin input type throught classes and testing each value
        /* On previous versions of this validation script, we only validate those field with the specified classes
        in this algorithm. Thinking about the process we realize that we weren't taking care of those 
        fields without validation classes, therefor we gave the user a big capability of writing harmful code. 
        For this reason we need to take care of two things, 1) Cases where the user erases the classes, and cases 
        where the user input harmful or just bad data into fields without validation. On this updated code, we manage
        the inputs with REQUIRE classes to those without it.*/
        
        /*///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////// CHECKING REQUIRE INPUTS ////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

        if($(this).hasClass("require")){
            if ((elementValue === null) || (elementValue.length === 0) || (elementValue === "undefined") || (/^\s+$/.test(elementValue)))
			{
                //Specifying the wrong input
                validationResult = {
                    "result" : false,
                    "msg" : messages.requireText
                };
        		$(this).addClass("invalid").focus();
                //Mark the flag
				flag = false;
                //Stoping the execution of the process
				return false;
			}else{
				$(this).removeClass("invalid").focus();
                /* String only inputs -----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                if ($(this).hasClass("text")){
                    if (elementValue.length > textLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + textLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Medium string inputs ---------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("medium_text")){
                    if (elementValue.length > mediumTextLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + mediumTextLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Long strings inputs ----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("large_text")){
                    if (elementValue.length > largeTextLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + largeTextLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                    
                /* Texto libre ------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("free_text")){
                    if (elementValue.length > 0) {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                /* Links inputs -----------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("link")){
                    if (elementValue.length > linkLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + linkLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Numbers only inputs ----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('num')){
                    if (isNaN(elementValue))
                    {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.numberText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length > numLength) {
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + numLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Email only inputs ------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('email')){
                    if (!(b.test(elementValue)))
                    {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.emailText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length < 5) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.emailText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length > emailLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + emailLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Hidden inputs ----------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("hidden")){
                    if (elementValue.length > hiddenLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.hiddenText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Combobox inputs --------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('choose')){
                    select = document.getElementById(inputName); // or in jQuery use: select = this;
                    if (select.value) {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    } else {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.chooseText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }

                /* Password inputs --------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('password')){
                    if (elementValue.length < passwordMinlength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textUnderText1 + passwordMinlength + messages.textUnderText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length > passwordMaxlength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + passwordMaxlength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (!(re.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordNumberText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if(!(lwl.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordLowerText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if(!(upl.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordUpperText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if(!(spc.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordSpecialText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Confirmation inputs (double value check) -------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('confirm_password')){

                    elementValueConfirmation = $(this).parents('form').find('.password').val();
                    if(elementValue != elementValueConfirmation)  {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordConfirmText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                }else{
                    if (elementValue.length > textLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + textLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                }
            }//End if the field does have a value
        /*///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////// CHECKING NOT REQUIRE INPUTS ///////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
        }else{
            if ((elementValue !== null) && (elementValue.length !== 0) && (elementValue !== "undefined"))
			{
                /* String only inputs -----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                if ($(this).hasClass("text")){
                    if (elementValue.length > textLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + textLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Medium string inputs ---------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("medium_text")){
                    if (elementValue.length > mediumTextLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + mediumTextLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Long strings inputs ----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("large_text")){
                    if (elementValue.length > largeTextLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + largeTextLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                /* Long strings inputs ----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("link")){
                    if (elementValue.length > linkLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + linkLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Numbers only inputs ----------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('num')){
                    if (isNaN(elementValue))
                    {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.numberText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length > numLength) {
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + numLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Email only inputs ------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('email')){
                    if (!(b.test(elementValue)))
                    {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.emailText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length < 5) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.emailText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length > emailLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + emailLength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Hidden inputs ----------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass("hidden")){
                    if (elementValue.length > hiddenLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.hiddenText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                    
                /* Password inputs --------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('password')){
                    if (elementValue.length < passwordMinlength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textUnderText1 + passwordMinlength + messages.textUnderText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (elementValue.length > passwordMaxlength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + passwordMaxlength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if (!(re.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordNumberText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if(!(lwl.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordLowerText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if(!(upl.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordUpperText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else if(!(spc.test(elementValue))) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordSpecialText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }

                /* Confirmation inputs (double value check) -------------------------------------------
                ---------------------------------------------------------------------------------------
                ---------------------------------------------------------------------------------------
                --------------------------------------------------------------------------------------*/
                }else if ($(this).hasClass('confirm_password')){

                    elementValueConfirmation = $('.password').val();

                    if(elementValue != elementValueConfirmation)  {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.passwordConfirmText
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                        $(this).val().trim();
                    }
                }else{
                    if (elementValue.length > textLength) {
                        //Printing result to guide the user
                        validationResult = {
                            "result" : false,
                            "msg" : messages.textText1 + passwordMaxlength + messages.textText2
                        };
		        		$(this).addClass("invalid").focus();
                        //Mark the flag
                        flag = false;
                        //Stoping the execution of the process
                        return false;
                    }
                    else
                    {
                        $(this).removeClass("invalid");
                    }
                }
            }//End if the field does have a value
        }//End if input does not have require class

    });//End of the form loop

	/* Sending final result ---------------------------------------------------------------
    ---------------------------------------------------------------------------------------
    ---------------------------------------------------------------------------------------
    --------------------------------------------------------------------------------------*/
    /*
	if(flag===true){
		return true;
		//return validationResult;
	}else{
        return false;
        //return validationResult;
    }*/

    return validationResult;
}//FIN FUNCIÓN